'use client'

import PlayseeLogo from 'asset/icon/logo/playsee_logo_new.svg'
import AuthButton from 'component/block/authButton'
import Link from 'component/block/link'
import { HEADER_LOCATION_CONTAINER_ID } from 'constant'
import { usePathname } from 'lib/next-intl'
import { usePickerOpenStore } from 'store/global/picker-open'
import { useDeviceStore, useIsMobile } from 'store/server-context/device'
import { useSessionStore } from 'store/server-context/session'
import { cn } from 'util/cn'
import currentPage from 'util/currentPage'
import styles from './header.module.css'
import { GetAppDesktop, GetAppTablet } from '../getApp'
import { usePopupStore } from 'store/global/popup'

const AppHeader = () => {
  const session = useSessionStore(state => state.session)
  const isDownloadOpen = usePopupStore(state => state.mask.download)
  const device = useDeviceStore(state => state.device)
  const pathname = usePathname()
  const { page, id } = currentPage(pathname)
  const isMobile = useIsMobile()
  const showCard = usePickerOpenStore(state => state.pickerOpen)

  const isSpotFeed = page === 'spot-feed'
  const isMyProfile =
    !session.guest &&
    (page === 'profile' || page === 'profile_new') &&
    (id === session.uid || id === session.user_id)

  const showAuth = session.guest || isMyProfile

  return (
    <div
      className={cn(
        isSpotFeed ? styles.spotHeaderWrap : styles.headerWrap,
        showCard && styles.showCard
      )}
    >
      <div className={styles.header}>
        <Link href='/community' title='Playsee app' className={styles.logoLink}>
          <PlayseeLogo className={styles.logo} />
        </Link>

        {isMobile && (
          <div
            id={HEADER_LOCATION_CONTAINER_ID}
            className='h-fit min-w-0 flex-shrink pl-[8px]'
          />
        )}

        {showAuth && (
          <div className='flex justify-end gap-2 ml-auto px-[16px] min1080:pr-[40px]'>
            {device === 'desktop' && (
              <GetAppDesktop
                variant={isMobile && isSpotFeed ? 'outline-white' : 'outline'}
                isMobile={isMobile}
              />
            )}

            {device === 'tablet' && !isDownloadOpen && (
              <GetAppTablet
                variant={isMobile && isSpotFeed ? 'outline-white' : 'outline'}
                isMobile={isMobile}
              />
            )}

            <AuthButton
              variant={isMobile && isSpotFeed ? 'outline-white' : 'outline'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AppHeader
