'use client'
import { useTranslations } from 'next-intl'

import IconHashtag from 'asset/icon/general/hashtag.svg'
import {
  ListContainer,
  LoadingWrapper,
  SuggestedForYou
} from 'component/block/layoutSuggested'
import Link from 'component/block/link'
import LoadingItems from 'component/block/loading/items'
import SuggestedListItem from 'component/block/suggestedListItem'
import numberFormat from 'util/numberFormat'
import { useHashtagSuggestion } from './use-hashtag-suggestion'

interface IProfileSuggested {
  hashtag: string
}
const HashtagSuggestion = (props: IProfileSuggested) => {
  const { hashtag } = props

  const { hashtagSuggestion, isLoading } = useHashtagSuggestion(hashtag, 10)

  const t = useTranslations()

  return (
    <div>
      <SuggestedForYou>{t('common.suggested_for_you')}</SuggestedForYou>
      <ListContainer>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          hashtagSuggestion.map(hashtag => {
            const { name, counts } = hashtag

            return (
              <Link
                key={name}
                href={`/hashtag/${encodeURIComponent(name)}`}
                title={name}
              >
                <SuggestedListItem
                  icon={<IconHashtag />}
                  title={`#${name}`}
                  subTitle={`${numberFormat(
                    counts?.spot_count || 0,
                    'compact'
                  )} ${t('common.posts')}`}
                />
              </Link>
            )
          })
        )}
      </ListContainer>
    </div>
  )
}

export default HashtagSuggestion
