import { twc } from 'util/cn'

// 360 - 16 * 2
export const SuggestedForYou = twc.div`mx-[16px] my-[12px] max-w-[328px] text-ellipsis text-2xl font-bold text-label-l1`
SuggestedForYou.displayName = 'SuggestedForYou'

export const CategoryTitle = twc.div`mx-[16px] mt-[12px] max-w-[328px] text-ellipsis text-lg font-bold text-label-l1`
CategoryTitle.displayName = 'CategoryTitle'

export const LoadingWrapper = twc.div`mb-[16px] ml-[16px] mt-[4px] w-[200px]`
LoadingWrapper.displayName = 'LoadingWrapper'

export const ListContainer = twc.div`pt-[4px] [&_.suggestedTitle]:text-md [&_.suggestedTitle]:font-bold`
ListContainer.displayName = 'ListContainer'

export const Title = twc.div`line-clamp-2 cursor-default px-[16px] py-[12px] text-lg font-bold text-label-l1`
Title.displayName = 'Title'
