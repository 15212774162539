'use client'

import React from 'react'

import CommunitySuggestion from 'component/page/community/community-suggestion'
import HashtagSuggestion from 'component/page/hashtag/hashtag-suggestion'
import SearchSuggestion from 'component/page/search/search-suggestion'
import SpotSuggestion from 'component/page/spot/spot-suggestion'
import PlaceSuggestion from 'component/page/place/place-suggestion'
import { ScrollArea } from 'component/ui/scroll-area'
import { usePathname } from 'lib/next-intl'
import cn from 'util/cn'
import currentPage from 'util/currentPage'
import debounce from 'util/debounce'
import Footer from '../footer/app-footer'

const SideRight = () => {
  const pathname = usePathname()
  const { page, id } = currentPage(pathname)
  const ref = React.useRef<HTMLDivElement>(null)

  const isSpotFeed = page === 'spot-feed'
  const isSpot = page === 'spot'
  const isPixie = page === 'pixie'

  React.useLayoutEffect(() => {
    if (!isSpotFeed) return

    const spotLayout = document.querySelector('.spot-layout')
    if (!spotLayout) return

    const container = ref.current
    if (!container) return

    const resize = debounce(200, () => {
      const layoutStyles = window.getComputedStyle(spotLayout)
      const sideRightWidth = parseInt(
        layoutStyles.getPropertyValue('--side-right-width'),
        10
      )
      const sideLeftWidth = parseInt(
        layoutStyles.getPropertyValue('--side-left-width'),
        10
      )
      const fixedTop = parseInt(
        layoutStyles.getPropertyValue('--fixed-top'),
        10
      )
      const videoPadding = parseInt(
        layoutStyles.getPropertyValue('--video-padding'),
        10
      )
      const scrollPaddingTop = parseInt(
        layoutStyles.getPropertyValue('--scroll-padding-top'),
        10
      )
      const scrollPaddingBottom = parseInt(
        layoutStyles.getPropertyValue('--scroll-padding-bottom'),
        10
      )
      const layoutPadding = 28 * 2

      const windowInnerHeight = window.innerHeight
      const windowInnerWidth = window.innerWidth - layoutPadding

      const videoHeightConstraint =
        fixedTop + scrollPaddingTop + scrollPaddingBottom
      const videoHeight = windowInnerHeight - videoHeightConstraint
      const videoWidth = (videoHeight / 708) * 398 + videoPadding * 2

      if (windowInnerWidth < videoWidth + sideLeftWidth + sideRightWidth) {
        container.style.display = 'none'
      } else {
        container.style.removeProperty('display')
      }
    })

    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [isSpotFeed])

  if (isSpot) {
    return null
  }

  let suggested = null

  switch (page) {
    case 'spot-feed':
      suggested = <SpotSuggestion />
      break
    case 'search':
    case 'pixie':
      suggested = <SearchSuggestion />
      break
    case 'community':
    case 'community-feed':
      suggested = <CommunitySuggestion />
      break
    case 'profile':
      // suggested = <UserSuggestion userId={id} />
      break
    case 'place':
      suggested = <PlaceSuggestion />

      break
    case 'profile_new':
      // suggested = <UserSuggestion uid={id} />
      break
    case 'hashtag':
      suggested = <HashtagSuggestion hashtag={id} />
      break
  }
  return (
    <div
      ref={ref}
      className='hidden w-[--side-right-width] shrink-0 grow-0 shadow-[inset_1px_0_0_0_var(--glass-special-separators)] min1080:block'
    >
      <div
        className={cn(
          'fixed bottom-0 w-[--side-right-width]',
          isSpotFeed || isPixie ? 'top-0' : 'top-[--fixed-top]'
        )}
      >
        <ScrollArea className='h-full' viewportClassName='overscroll-y-contain'>
          {suggested}
          <div className='mt-auto pb-[80px]'>
            <Footer />
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default SideRight
