import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/(app)/(withSidebar)/reset-user-paused.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/accountModal/accountModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/authModal/authModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/header/app-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/layout/with-sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/overlays/overlays.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
